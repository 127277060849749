import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { CurrentUserProvider } from "./context/user-context";
const queryClient = new QueryClient();

const theme = createTheme({
  palette: {
    primary: {
      main: "#1fb723",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          boxShadow: "none",
          backdropFilter: "blur(20px)",
          borderStyle: "solid",
          borderColor: "rgb(231, 235, 240)",
          borderWidth: "0 0 thin",
          color: "rgb(45, 56, 67)",
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <CurrentUserProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </BrowserRouter>
      </ThemeProvider>
    </CurrentUserProvider>
  </React.StrictMode>
);
