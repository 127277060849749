import { TableCell, TableRow, Tooltip } from "@mui/material";
import React from "react";

const TemplateRow = (row: { template: any; viewTemplate: () => void }) => {
  const { template, viewTemplate } = row;

  return (
    <React.Fragment>
      <Tooltip title="View Template" placement="top">
        <TableRow
          onClick={viewTemplate}
          sx={{
            cursor: "pointer",
            "& > *": { borderBottom: "unset" },
          }}
          hover
        >
          <TableCell key={"name"}>{template.name}</TableCell>
          <TableCell key={"platform"}>
            {template.platform.charAt(0) +
              template.platform.substring(1).toLowerCase()}
          </TableCell>
          <TableCell key={"status"}>
            {template.status.charAt(0) +
              template.status.substring(1).toLowerCase()}
          </TableCell>
        </TableRow>
      </Tooltip>
    </React.Fragment>
  );
};

export default TemplateRow;
