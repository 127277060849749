import {
  Button,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TableCell,
  TableRow,
} from "@mui/material";
import React from "react";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DeleteIcon from "@mui/icons-material/Delete";

const Capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export type Promotion = {
  id: string;
  first_name: string;
  last_name: string;
  trial_days: number;
  status: string;
  needs_payment: boolean;
};

const PromoRow = (row: {
  promo: Promotion;
  onActivate: () => void;
  onDeactivate: () => void;
  onCopy: () => void;
  onDelete: (id: string) => void;
}) => {
  const { promo, onActivate, onDeactivate, onCopy, onDelete } = row;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    if (name === "active") {
      onDeactivate();
    } else {
      onActivate();
    }
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell key={"name"}>
          {promo.first_name} {promo.last_name}
        </TableCell>
        <TableCell key={"trial_days"}>{promo.trial_days} days</TableCell>
        <TableCell key={"status"}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={promo.status === "active"}
                  onChange={handleChange}
                  name={promo.status}
                />
              }
              label={Capitalize(promo.status)}
            />
          </FormGroup>
        </TableCell>
        <TableCell key={"need_payment"}>
          {promo.needs_payment ? "✅" : "❌"}
        </TableCell>

        <TableCell key={"more"}>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              onClick={() => {
                navigator.clipboard.writeText(
                  promo.needs_payment
                    ? "https://wisesheets.io/payment?promo=" + promo.id
                    : "https://wisesheets.io/signup?promo=" + promo.id
                );
                onCopy();
              }}
              startIcon={<ContentPasteIcon />}
              size="small"
            >
              Copy URL
            </Button>
            <Button
              variant="outlined"
              onClick={async () => {
                onDelete(promo.id);
              }}
              startIcon={<DeleteIcon />}
              size="small"
              color="error"
            >
              Delete
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default PromoRow;
