import create from "zustand";

export type Error = {
  hasError: boolean;
  errorMessage: string;
};

export type ErrorStore = {
  error: Error;
  setError: (error: Error) => void;
};

export const useErrorStore = create<ErrorStore>((set) => ({
  error: { hasError: false, errorMessage: "" },
  setError: (error: Error) => set({ error: error }),
}));

export type Success = {
  hasSuccess: boolean;
  successMessage: string;
};

export type SuccessStore = {
  success: Success;
  setSuccess: (success: Success) => void;
};

export const useSuccessStore = create<SuccessStore>((set) => ({
  success: { hasSuccess: false, successMessage: "" },
  setSuccess: (success: Success) => set({ success: success }),
}));

export type LoadingStore = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

export const useLoadingStore = create<LoadingStore>((set) => ({
  loading: false,
  setLoading: (loading: boolean) => set({ loading: loading }),
}));

export type AreYouSure = {
  showAreYouSure: boolean;
  areYouSureMessage: string;
  areYouSureAction: () => void;
};

export type AreYouSureStore = {
  areYouSure: AreYouSure;
  setAreYouSure: (areYouSure: AreYouSure) => void;
};

export const useAreYouSureStore = create<AreYouSureStore>((set) => ({
  areYouSure: {
    showAreYouSure: false,
    areYouSureMessage: "",
    areYouSureAction: () => {},
  },
  setAreYouSure: (areYouSure: AreYouSure) => set({ areYouSure: areYouSure }),
}));
