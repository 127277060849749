import { Navigate } from "react-router-dom";

type ProtectedType = {
  isLoggedIn: boolean;
  children: JSX.Element;
};

const Protected = ({ isLoggedIn, children }: ProtectedType): JSX.Element => {
  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }
  return children;
};
export default Protected;
