import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { useMutation, useQuery } from "react-query";
import CloseIcon from "@mui/icons-material/Close";
import PromoRow, { Promotion } from "../../components/PromoRow/PromoRow";
import { useForm } from "react-hook-form";
import { httpClient } from "../../util/http";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import moment from "moment";
import {
  useAreYouSureStore,
  useErrorStore,
  useLoadingStore,
  useSuccessStore,
} from "../../state/store";

interface Column {
  id: "name" | "trial_days" | "status" | "needs_payment";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  {
    id: "name",
    label: "Promoter Name",
    minWidth: 170,
  },
  {
    id: "trial_days",
    label: "Trial days",
    minWidth: 170,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
  },
  {
    id: "needs_payment",
    label: "Requires payment",
    minWidth: 170,
  },
];

const Promotions = () => {
  const [date, setDate] = React.useState<Dayjs | null>(null);

  const { register, handleSubmit, reset, setValue, getValues } = useForm();

  const { setSuccess } = useSuccessStore((state) => state);
  const { setError } = useErrorStore((state) => state);
  const { setLoading } = useLoadingStore((state) => state);
  const { setAreYouSure } = useAreYouSureStore((state) => state);
  const promotions = useQuery(["promotions"], () =>
    httpClient.get("/api/private/promotions")
  );
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const calculateTrialDays = (endDate: string): number => {
    return moment(endDate).startOf("day").diff(moment().startOf("day"), "days");
  };

  const createPromo = useMutation(
    (data: {
      firstName: string;
      lastName: string;
      trialEndDate: string;
      needsPayment: boolean;
    }) =>
      httpClient.post("/api/private/promotions", {
        first_name: data.firstName,
        last_name: data.lastName,
        trial_days: calculateTrialDays(data.trialEndDate),
        needs_payment: data.needsPayment,
      }),
    {
      onMutate: () => {
        handleDialogClose();
        setLoading(true);
      },
      onSuccess: () => {
        promotions.refetch();
        setLoading(false);
        setSuccess({
          hasSuccess: true,
          successMessage: "Promotion added.",
        });
      },
      onError: (e) => {
        setLoading(false);
        console.log(e);
        setError({
          hasError: true,
          errorMessage:
            "Something went wrong. Talk to Sheldon and send the console error.",
        });
      },
    }
  );

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    reset();
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const updatePromo = useMutation(
    (data: { promoId: string; status: string }) =>
      httpClient.put(`/api/private/promotions/status`, {
        id: data.promoId,
        status: data.status,
      }),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        promotions.refetch();
        setLoading(false);
        setSuccess({
          hasSuccess: true,
          successMessage: "Promotion updated.",
        });
      },
      onError: (e) => {
        setLoading(false);
        console.log(e);
        setError({
          hasError: true,
          errorMessage:
            "Something went wrong. Talk to Sheldon and send the console error.",
        });
      },
    }
  );

  const deletePromo = useMutation(
    (promoId: string) =>
      httpClient.delete(`/api/private/promotions?id=${promoId}`),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        promotions.refetch();
        setLoading(false);
        setSuccess({
          hasSuccess: true,
          successMessage: "Promotion deleted.",
        });
        setAreYouSure({
          showAreYouSure: false,
          areYouSureMessage: "",
          areYouSureAction: () => {},
        });
      },
      onError: (e) => {
        setLoading(false);
        console.log(e);
        setError({
          hasError: true,
          errorMessage:
            "Something went wrong. Talk to Sheldon and send the console error.",
        });
        setAreYouSure({
          showAreYouSure: false,
          areYouSureMessage: "",
          areYouSureAction: () => {},
        });
      },
    }
  );

  return (
    <>
      <Toolbar />
      <Box
        sx={{
          p: 2,
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", marginBottom: 1 }}
        >
          <Button variant="outlined" onClick={handleOpen}>
            New Promo
          </Button>
        </Box>
        {promotions.isLoading ? (
          <CircularProgress />
        ) : (
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
            }}
          >
            <TableContainer sx={{ maxHeight: "auto" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {promotions.data?.data.map((promo: Promotion) => {
                    return (
                      <PromoRow
                        key={promo.id}
                        promo={promo}
                        onActivate={() =>
                          updatePromo.mutate({
                            promoId: promo.id,
                            status: "active",
                          })
                        }
                        onDeactivate={() =>
                          updatePromo.mutate({
                            promoId: promo.id,
                            status: "expired",
                          })
                        }
                        onCopy={() => setOpenSnackbar(true)}
                        onDelete={() =>
                          setAreYouSure({
                            showAreYouSure: true,
                            areYouSureMessage:
                              "Are you sure you want to delete this promo?",
                            areYouSureAction: () =>
                              deletePromo.mutate(promo.id),
                          })
                        }
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
        {/* TODO: make these ones function based on context or app state so they arent declared in
         every component that needs it and are just in the root app component */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Copied to clipboard
          </Alert>
        </Snackbar>
        <Dialog open={openDialog} maxWidth="sm" fullWidth>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <Typography>Add Promo</Typography>
            <IconButton
              aria-label="close"
              onClick={handleDialogClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[900],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form
            onSubmit={handleSubmit((data) => createPromo.mutate(data as any))}
            style={{ display: "flex", flexDirection: "column", padding: 16 }}
          >
            <Stack spacing={2}>
              <TextField
                label="First name"
                type="text"
                autoFocus
                required
                {...register("firstName")}
              />
              <TextField
                label="Last name"
                type="text"
                required
                {...register("lastName")}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Trial end date"
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue);
                    setValue("trialEndDate", newValue?.format("MM/DD/YYYY"));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      {...register("trialEndDate")}
                    />
                  )}
                />
              </LocalizationProvider>
              <Typography fontSize={14}>
                Each user that signs up for this promotion will have a{" "}
                {calculateTrialDays(getValues("trialEndDate"))} day trial from
                the day they sign up or pay
              </Typography>
              <FormControlLabel
                control={<Checkbox {...register("needsPayment")} />}
                label="Requires payment?"
              />
              <Button type="submit" variant="outlined">
                Add
              </Button>
            </Stack>
          </form>
        </Dialog>
      </Box>
    </>
  );
};

export default Promotions;
