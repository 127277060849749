import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import GoogleIcon from "@mui/icons-material/Google";

const ITEM_HEIGHT = 48;

const UserRow = (row: {
  user: any;
  onCancel: () => void;
  onEnable: () => void;
  onDisable: () => void;
}) => {
  const { user, onCancel, onDisable, onEnable } = row;
  const [open, setOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell key={"name"}>
          {user.first_name} {user.last_name}
        </TableCell>
        <TableCell key={"email"}>{user.email}</TableCell>
        <TableCell key={"subscribed"}>
          {user.subscribed ? "✅" : "❌"}
        </TableCell>
        <TableCell key={"sso-provider"}>
          {user.sso_provider === null ? (
            "None"
          ) : user.sso_provider === "GOOGLE" ? (
            <GoogleIcon />
          ) : (
            user.sso_provider
          )}
        </TableCell>
        <TableCell>
          <IconButton aria-label="edit row" size="small" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
              },
            }}
          >
            <MenuItem key={"cancel_sub"} onClick={onCancel}>
              Cancel subscripion at period end
            </MenuItem>
            {!user.subscribed ? (
              <MenuItem key={"enable_manual_sub"} onClick={onEnable}>
                Enable sub manually
              </MenuItem>
            ) : null}
            {user.subscribed && user.stripe_subscription_id === "manual" ? (
              <MenuItem key={"enable_manual_sub"} onClick={onDisable}>
                Disable sub manually
              </MenuItem>
            ) : null}
          </Menu>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ my: 2, display: "flex", justifyContent: "center" }}>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
              >
                <Typography fontSize={12}>
                  User id: {user.excel_user_id}
                </Typography>
                <Typography fontSize={12}>
                  Account created:{" "}
                  {Moment(user.date_created).format("MMMM Do YYYY")}
                </Typography>
                {user.subscribed ? (
                  <Typography fontSize={12}>
                    Subscription started:{" "}
                    {Moment(user.subscription_start_date).format(
                      "MMMM Do YYYY"
                    )}
                  </Typography>
                ) : null}
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default UserRow;
