import axios from "axios";

export const httpClient = axios.create({
  // baseURL: "http://localhost:3000/api",
  timeout: 60000,
});

// Add a request interceptor
httpClient.interceptors.request.use(
  async (config) => {
    // Add auth token to header of each request if available
    const token = window.localStorage.getItem("token");
    if (config.headers !== undefined) {
      if (token !== null) {
        config.headers.Authorization = token;
      }
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
httpClient.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  async (error) => {
    // If the response is a 401 (Unauthenticated) log the user out of the app
    // since their token isn't good anyway
    if (error.response?.status === 403) {
      window.localStorage.removeItem("token");
      window.location.reload();
    }
    return Promise.reject(error);
  }
);
