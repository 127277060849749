import { Link, Outlet, useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import {
  Alert,
  AppBar,
  Backdrop,
  Button,
  CircularProgress,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Snackbar,
  Toolbar,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import React, { useEffect } from "react";
import DiscountIcon from "@mui/icons-material/Discount";
import LogoutIcon from "@mui/icons-material/Logout";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MenuIcon from "@mui/icons-material/Menu";
import { useCurrentUser } from "../../context/user-context";
import {
  useAreYouSureStore,
  useErrorStore,
  useLoadingStore,
  useSuccessStore,
} from "../../state/store";

const drawerWidth = 240;

const ROUTES = [
  {
    path: "/admin/home",
    name: "Home",
    icon: <HomeIcon />,
  },
  {
    path: "/admin/customers",
    name: "Customers",
    icon: <AccountCircleIcon />,
  },
  {
    path: "/admin/promotions",
    name: "Promotions",
    icon: <DiscountIcon />,
  },
  {
    path: "/admin/templates",
    name: "Templates",
    icon: <FileCopyIcon />,
  },
];

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

function Admin(props: Props) {
  const location = useLocation();
  const [title, setTitle] = React.useState("");
  const { logout, currentUser } = useCurrentUser();

  const { success, setSuccess } = useSuccessStore((state) => state);
  const { error, setError } = useErrorStore((state) => state);
  const { loading } = useLoadingStore((state) => state);
  const { areYouSure, setAreYouSure } = useAreYouSureStore((state) => state);

  useEffect(() => {
    setTitle(() => {
      if (ROUTES.filter((r) => r.path === location.pathname)[0]) {
        return ROUTES.filter((r) => r.path === location.pathname)[0].name;
      }
      return "";
    });
  }, [location.pathname]);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <div>
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "fit-content",
          }}
        >
          <img
            src={require("../../logo.png")}
            alt="logo"
            height={30}
            width={30}
          />
          <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
          <Typography variant="h6" noWrap>
            Wisesheets
          </Typography>
        </Box>
      </Toolbar>
      <Divider />
      <List>
        {ROUTES.map((route) => (
          <Link
            to={route.path}
            style={{ textDecoration: "none", color: "inherit" }}
            key={route.name}
          >
            <ListItem key={route.name} dense>
              <ListItemButton
                selected={title === route.name}
                dense
                sx={{ borderRadius: 1 }}
              >
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.name} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
      <List sx={{ position: "absolute", bottom: 0, width: "100%" }}>
        <ListItem key={"logout"} dense onClick={logout}>
          <ListItemButton dense sx={{ borderRadius: 1 }}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={"Log out"} />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  return (
    <Box display={"flex"}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1500 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
          color="primary"
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap marginRight={"auto"}>
              {title}
            </Typography>
            <Typography variant="subtitle1" noWrap>
              {currentUser.firstName} {currentUser.lastName}
            </Typography>
          </Toolbar>
        </AppBar>
        {/* SUCCESS SNACKBAR */}
        <Snackbar
          open={success.hasSuccess}
          autoHideDuration={6000}
          onClose={() => setSuccess({ hasSuccess: false, successMessage: "" })}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() =>
              setSuccess({ hasSuccess: false, successMessage: "" })
            }
            severity="success"
            sx={{ width: "100%" }}
          >
            {success.successMessage}
          </Alert>
        </Snackbar>

        {/* ERROR SNACKBAR */}
        <Snackbar
          open={error.hasError}
          autoHideDuration={6000}
          onClose={() => setError({ hasError: false, errorMessage: "" })}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setError({ hasError: false, errorMessage: "" })}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error.errorMessage}
          </Alert>
        </Snackbar>
        <Modal
          open={areYouSure.showAreYouSure}
          onClose={() =>
            setAreYouSure({
              showAreYouSure: false,
              areYouSureMessage: "",
              areYouSureAction: () => {},
            })
          }
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {areYouSure.areYouSureMessage}
            </Typography>
            <Button onClick={areYouSure.areYouSureAction} color="success">
              Yes
            </Button>
            <Button
              onClick={() =>
                setAreYouSure({
                  showAreYouSure: false,
                  areYouSureMessage: "",
                  areYouSureAction: () => {},
                })
              }
              color="error"
            >
              Cancel
            </Button>
          </Box>
        </Modal>

        <Outlet />
      </Box>
    </Box>
  );
}

export default Admin;
