import { useMutation, useQuery } from "react-query";
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
} from "@mui/material";
import React from "react";
import UserRow from "../../components/UserRow/UserRow";
import { httpClient } from "../../util/http";
import SearchIcon from "@mui/icons-material/Search";
import {
  useAreYouSureStore,
  useErrorStore,
  useLoadingStore,
  useSuccessStore,
} from "../../state/store";

interface Column {
  id: "id" | "name" | "email" | "subscribed" | "sso-provider";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  {
    id: "name",
    label: "Name",
    minWidth: 170,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
  },
  {
    id: "subscribed",
    label: "Subscribed",
    minWidth: 170,
  },
  {
    id: "sso-provider",
    label: "SSO Provider",
    minWidth: 170,
  },
];

const Customers = () => {
  const { setAreYouSure } = useAreYouSureStore((state) => state);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchValue, setsSarchValue] = React.useState<string>("");
  const users = useQuery(
    ["users", page],
    () =>
      httpClient.get(
        `/api/private/customers?page=${page}&rowsPerPage=${rowsPerPage}&query=${searchValue}`
      ),
    { keepPreviousData: true }
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    users.refetch();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setsSarchValue(event.target.value);
  };

  const handleSearch = () => {
    users.refetch();
  };
  const handleKeyPress = (e: { keyCode: number }) => {
    if (e.keyCode === 13) {
      users.refetch();
    }
  };

  const { setSuccess } = useSuccessStore((state) => state);
  const { setError } = useErrorStore((state) => state);
  const { setLoading } = useLoadingStore((state) => state);

  const cancelSubscription = useMutation(
    (stripe_subscription_id: string) =>
      httpClient.post(
        `/api/private/customers/cancel-subscription-at-cycle-end`,
        {
          stripe_subscription_id,
        }
      ),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        setLoading(false);
        setSuccess({
          hasSuccess: true,
          successMessage:
            "Customers subscription will stop at the end of their billing cycle.",
        });
        setAreYouSure({
          showAreYouSure: false,
          areYouSureMessage: "",
          areYouSureAction: () => {},
        });
      },
      onError: (e) => {
        console.log(e);
        setError({
          hasError: true,
          errorMessage:
            "Something went wrong. Talk to Sheldon and send the console error.",
        });
        setLoading(false);
        setAreYouSure({
          showAreYouSure: false,
          areYouSureMessage: "",
          areYouSureAction: () => {},
        });
      },
    }
  );

  const enableManualSub = useMutation(
    (userId: string) =>
      httpClient.post(`/api/private/customers/enable-manual-sub`, {
        userId,
      }),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        setLoading(false);
        setSuccess({
          hasSuccess: true,
          successMessage: "Customers subscription has been enabled manually",
        });
        setAreYouSure({
          showAreYouSure: false,
          areYouSureMessage: "",
          areYouSureAction: () => {},
        });
        users.refetch();
      },
      onError: (e) => {
        console.log(e);
        setError({
          hasError: true,
          errorMessage:
            "Something went wrong. Talk to Sheldon and send the console error.",
        });
        setAreYouSure({
          showAreYouSure: false,
          areYouSureMessage: "",
          areYouSureAction: () => {},
        });
        setLoading(false);
      },
    }
  );

  const disableManualSub = useMutation(
    (userId: string) =>
      httpClient.post(`/api/private/customers/disable-manual-sub`, {
        userId,
      }),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        setLoading(false);
        setSuccess({
          hasSuccess: true,
          successMessage: "Customers subscription has been disabled manually",
        });
        setAreYouSure({
          showAreYouSure: false,
          areYouSureMessage: "",
          areYouSureAction: () => {},
        });
        users.refetch();
      },
      onError: (e) => {
        console.log(e);
        setError({
          hasError: true,
          errorMessage:
            "Something went wrong. Talk to Sheldon and send the console error.",
        });
        setAreYouSure({
          showAreYouSure: false,
          areYouSureMessage: "",
          areYouSureAction: () => {},
        });
        setLoading(false);
      },
    }
  );

  return (
    <>
      <Toolbar />
      <Box
        sx={{
          p: 2,
        }}
      >
        {users.isLoading ? (
          <CircularProgress />
        ) : (
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
            }}
          >
            <TextField
              label="Search"
              sx={{ m: 1, width: "32ch" }}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              InputProps={{
                startAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleSearch}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
            <TableContainer sx={{ maxHeight: "auto" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.data?.data.map((user: any) => {
                    return (
                      <UserRow
                        key={user.excel_user_id}
                        user={user}
                        onCancel={() =>
                          setAreYouSure({
                            showAreYouSure: true,
                            areYouSureMessage:
                              "Are you sure you want to cancel this subscription?",
                            areYouSureAction: () =>
                              cancelSubscription.mutate(
                                user.stripe_subscription_id
                              ),
                          })
                        }
                        onEnable={() =>
                          setAreYouSure({
                            showAreYouSure: true,
                            areYouSureMessage:
                              "Are you sure you want to enable this subscription?",
                            areYouSureAction: () =>
                              enableManualSub.mutate(user.excel_user_id),
                          })
                        }
                        onDisable={() =>
                          setAreYouSure({
                            showAreYouSure: true,
                            areYouSureMessage:
                              "Are you sure you want to disable this subscription?",
                            areYouSureAction: () =>
                              disableManualSub.mutate(user.excel_user_id),
                          })
                        }
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={
                users.data?.data.length === 0
                  ? 0
                  : users.data?.data[0].user_count
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </Box>
    </>
  );
};

export default Customers;
