import { useMutation, useQuery } from "react-query";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { httpClient } from "../../util/http";
import TemplateRow from "../../components/TemplateRow/TemplateRow";
import SearchIcon from "@mui/icons-material/Search";
import {
  useAreYouSureStore,
  useErrorStore,
  useLoadingStore,
  useSuccessStore,
} from "../../state/store";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import Dropzone from "react-dropzone";
import { BsCloudArrowUpFill } from "react-icons/bs";
import { AiFillCheckCircle, AiFillFileImage } from "react-icons/ai";
import styles from "./Templates.module.css";
import { Reorder } from "framer-motion";
import { Item } from "./Item";

interface Column {
  id: "name" | "platform" | "status";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  {
    id: "name",
    label: "Name",
    minWidth: 170,
  },
  {
    id: "platform",
    label: "Platform",
    minWidth: 170,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
  },
];

const Templates = () => {
  const [state, setState] = useState({
    published: true,
    unpublished: true,
  });

  const [platform, setPlatform] = useState({
    sheets: true,
    excel: true,
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
  const [selectedImageData, setSelectedImageData] = useState<string>("");
  const [DialogType, setDialogType] = useState<"save" | "edit">("save");

  const [searchQuery, setSearchQuery] = useState("");
  const { setSuccess } = useSuccessStore((state) => state);
  const { setError } = useErrorStore((state) => state);
  const { setLoading } = useLoadingStore((state) => state);
  const { setAreYouSure } = useAreYouSureStore((state) => state);
  const { register, handleSubmit, reset, setValue, watch } = useForm();
  const watchPlatform = watch("platform", "Excel");

  const [excelTemplates, setExcelTemplates] = useState<any[]>([]);
  const [sheetsTemplates, setSheetsTemplates] = useState<any[]>([]);

  const templates = useQuery(
    ["templates"],
    () => httpClient.get(`/api/private/templates?query=${searchQuery}`),
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    setValue("platform", "EXCEL");
    setExcelTemplates(
      templates.data?.data
        .filter((template: any) => template.platform === "EXCEL")
        .sort((a: any, b: any) => a.sort_order - b.sort_order)
    );
    setSheetsTemplates(
      templates.data?.data
        .filter((template: any) => template.platform === "SHEETS")
        .sort((a: any, b: any) => a.sort_order - b.sort_order)
    );
  }, [setValue, setExcelTemplates, setSheetsTemplates, templates.data]);

  const handleDialogClose = () => {
    setOpenDialog(false);
    reset();
    setTemplateToView(null);
    setValue("platform", "EXCEL");
    setSelectedFile(null);
    setSelectedImageFile(null);
    setSelectedImageData("");
  };

  const deleteTemplate = useMutation(
    (data: { id: string }) =>
      httpClient.delete(`/api/private/templates?id=${data.id}`),
    {
      onMutate: () => {
        setOpenDialog(false);
        setLoading(true);
      },
      onSuccess: () => {
        templates.refetch();
        setLoading(false);
        setSuccess({
          hasSuccess: true,
          successMessage: "Deleted template.",
        });
        setAreYouSure({
          showAreYouSure: false,
          areYouSureMessage: "",
          areYouSureAction: () => {},
        });
      },
      onError: (e) => {
        setLoading(false);
        console.log(e);
        setError({
          hasError: true,
          errorMessage:
            "Something went wrong. Talk to Sheldon and send the console error.",
        });
        setAreYouSure({
          showAreYouSure: false,
          areYouSureMessage: "",
          areYouSureAction: () => {},
        });
      },
    }
  );

  const updateTemplateStatus = useMutation(
    (data: { id: string; status: "PUBLISHED" | "UNPUBLISHED" }) =>
      httpClient.patch(`/api/private/templates/update-status`, {
        id: data.id,
        status: data.status,
      }),
    {
      onMutate: () => {
        setOpenDialog(false);
        setLoading(true);
      },
      onSuccess: () => {
        templates.refetch();
        setLoading(false);
        setSuccess({
          hasSuccess: true,
          successMessage: "Template status updated.",
        });
        setAreYouSure({
          showAreYouSure: false,
          areYouSureMessage: "",
          areYouSureAction: () => {},
        });
      },
      onError: (e) => {
        setLoading(false);
        console.log(e);
        setError({
          hasError: true,
          errorMessage:
            "Something went wrong. Talk to Sheldon and send the console error.",
        });
        setAreYouSure({
          showAreYouSure: false,
          areYouSureMessage: "",
          areYouSureAction: () => {},
        });
      },
    }
  );

  const [templateToView, setTemplateToView] = React.useState<any>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    templates.refetch();
  };
  const handleKeyPress = (e: { keyCode: number }) => {
    if (e.keyCode === 13) {
      templates.refetch();
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const handlePlatformCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPlatform({
      ...platform,
      [event.target.name]: event.target.checked,
    });
  };

  const { published, unpublished } = state;
  const { sheets, excel } = platform;

  const filteredArray = templates.data?.data.filter((template: any) => {
    let shouldShowPublishedTemplate = false;
    let shouldShowUnpublishedTemplate = false;
    let shouldShowSheetsTemplate = false;
    let shouldShowExcelTemplate = false;
    if (state.published) {
      shouldShowPublishedTemplate = template.status === "PUBLISHED";
    }
    if (state.unpublished) {
      shouldShowUnpublishedTemplate = template.status === "UNPUBLISHED";
    }

    if (platform.sheets) {
      shouldShowSheetsTemplate = template.platform === "SHEETS";
    }

    if (platform.excel) {
      shouldShowExcelTemplate = template.platform === "EXCEL";
    }
    return (
      (shouldShowPublishedTemplate || shouldShowUnpublishedTemplate) &&
      (shouldShowSheetsTemplate || shouldShowExcelTemplate)
    );
  });

  const onFileSelect = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 1) {
      setError({
        hasError: true,
        errorMessage: "Can only upload one file.",
      });
      return;
    }
    const file = acceptedFiles[0];
    const fileToUpload = new File([file], `${file.name}`, {
      type: file.type,
    });
    setSelectedFile(fileToUpload);
  };

  const onImageFileSelect = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 1) {
      setError({
        hasError: true,
        errorMessage: "Can only upload one file.",
      });
      return;
    }
    const file = acceptedFiles[0];
    const fileExtension = file.name.split(".").pop();
    const fileToUpload = new File([file], `${uuidv4()}.${fileExtension}`, {
      type: file.type,
    });
    setSelectedImageFile(fileToUpload);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const dataUrl = reader.result;
      setSelectedImageData(dataUrl as string);
    };
  };

  const onSubmit = async (values: any) => {
    setLoading(true);
    if (DialogType === "save") {
      if (values.platform === "EXCEL") {
        if (!selectedFile) {
          setLoading(false);
          setError({
            hasError: true,
            errorMessage: `You need to select a file!`,
          });
          return;
        }
        const formData = new FormData();
        formData.append("Files", selectedFile);
        formData.append("Files", selectedImageFile as File);
        formData.append("name", values.name.trim());
        formData.append("description", values.description.trim());
        formData.append("platform", "EXCEL");
        try {
          await httpClient.post(
            "/api/private/templates/create-excel-template",
            formData
          );
        } catch (e) {
          console.log(e);
          setError({ hasError: true, errorMessage: "Error saving template" });
        } finally {
          setLoading(false);
          handleDialogClose();
          templates.refetch();
        }
      } else {
        try {
          const formData = new FormData();
          formData.append("Files", selectedImageFile as File);
          formData.append("name", values.name.trim());
          formData.append("description", values.description.trim());
          formData.append("platform", "SHEETS");
          formData.append("url", values.sheetsUrl.trim());
          await httpClient.post(
            "/api/private/templates/create-sheets-template",
            formData
          );
        } catch (e) {
          console.log(e);
          setError({ hasError: true, errorMessage: "Error saving template" });
        } finally {
          setLoading(false);
          handleDialogClose();
          templates.refetch();
        }
      }
    } else if (DialogType === "edit") {
      if (values.platform === "EXCEL") {
        const formData = new FormData();
        formData.append("Files", selectedFile as Blob);
        formData.append("Files", selectedImageFile as Blob);
        formData.append("name", values.name.trim());
        formData.append("description", values.description.trim());
        formData.append("platform", "EXCEL");

        try {
          await httpClient.patch(
            `/api/private/templates/update-excel-template/${templateToView.id}`,
            formData
          );
        } catch (e) {
          console.log(e);
          setError({ hasError: true, errorMessage: "Error updating template" });
        } finally {
          setLoading(false);
          handleDialogClose();
          templates.refetch();
        }
      } else {
        try {
          const formData = new FormData();
          formData.append("Files", selectedImageFile as Blob);
          formData.append("name", values.name.trim());
          formData.append("description", values.description.trim());
          formData.append("platform", "SHEETS");
          formData.append("url", values.sheetsUrl.trim());

          await httpClient.patch(
            `/api/private/templates/update-sheets-template/${templateToView.id}`,
            formData
          );
        } catch (e) {
          console.log(e);
          setError({ hasError: true, errorMessage: "Error updating template" });
        } finally {
          setLoading(false);
          handleDialogClose();
          templates.refetch();
        }
      }
    }
  };

  const convertDate = (date: string) => {
    const d = new Date(date);
    return d.toLocaleDateString();
  };

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Toolbar />
      <Box
        sx={{
          p: 2,
        }}
      >
        {templates.isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Paper
              sx={{
                width: "100%",
                overflow: "hidden",
              }}
            >
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="All Templates" />
                <Tab label="Re-order" />
              </Tabs>
              {tabValue === 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: 10,
                    }}
                  >
                    <TextField
                      label="Search"
                      sx={{ m: 1, width: "32ch" }}
                      onChange={handleChange}
                      onKeyDown={handleKeyPress}
                      InputProps={{
                        startAdornment: (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleSearch}
                          >
                            <SearchIcon />
                          </IconButton>
                        ),
                      }}
                    />
                    <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={published}
                            onChange={handleCheckboxChange}
                            name="published"
                          />
                        }
                        label="Published"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={unpublished}
                            onChange={handleCheckboxChange}
                            name="unpublished"
                          />
                        }
                        label="Unpublished"
                      />
                    </FormGroup>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ margin: "8px 0" }}
                    />
                    <FormGroup
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: 2,
                        marginRight: "auto",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sheets}
                            onChange={handlePlatformCheckboxChange}
                            name="sheets"
                          />
                        }
                        label="Sheets"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={excel}
                            onChange={handlePlatformCheckboxChange}
                            name="excel"
                          />
                        }
                        label="Excel"
                      />
                    </FormGroup>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 8,
                      }}
                    >
                      <Typography
                        variant="body2"
                        component="div"
                        sx={{ flexGrow: 1, marginRight: 2 }}
                      >
                        Showing {filteredArray.length} results
                      </Typography>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setOpenDialog(true);
                          setDialogType("save");
                        }}
                      >
                        New
                      </Button>
                    </div>
                  </div>
                  <TableContainer sx={{ maxHeight: "auto" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredArray.map((template: any) => {
                          return (
                            <TemplateRow
                              key={template.id}
                              template={template}
                              viewTemplate={() => {
                                setOpenDialog(true);
                                setValue("name", template.name);
                                setValue("description", template.description);
                                setValue("platform", template.platform);
                                setValue("sheetsUrl", template.url);
                                setTemplateToView(template);
                                setDialogType("edit");
                              }}
                            />
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <>
                  <Box p={2} display={"flex"} alignItems={"center"}>
                    <Typography variant="body2" marginRight={"auto"}>
                      Drag to re-order the templates then hit save to keep the
                      order.
                    </Typography>
                    <Button
                      variant="outlined"
                      onClick={async () => {
                        setLoading(true);
                        const updatedSortSheets = sheetsTemplates.map(
                          (template: any, index: number) => {
                            return {
                              id: template.id,
                              sortOrder: index,
                            };
                          }
                        );
                        const updateSortExcel = excelTemplates.map(
                          (template: any, index: number) => {
                            return {
                              id: template.id,
                              sortOrder: index,
                            };
                          }
                        );
                        await httpClient.post("/api/private/templates/sort", {
                          templates: [...updatedSortSheets, ...updateSortExcel],
                        });
                        setSuccess({
                          hasSuccess: true,
                          successMessage: "Template sort order updated.",
                        });
                        setLoading(false);
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                  <Box p={2} display={"flex"} columnGap={10}>
                    <Box width={"50%"}>
                      <Typography variant="h6">Sheets</Typography>
                      <Reorder.Group
                        className={styles.reorderGroup}
                        axis="y"
                        values={sheetsTemplates || []}
                        onReorder={(value) => {
                          setSheetsTemplates(value);
                        }}
                      >
                        {sheetsTemplates?.map(
                          (template: any, index: number) => (
                            <Item
                              key={template.id}
                              item={template}
                              index={index}
                            />
                          )
                        )}
                      </Reorder.Group>
                    </Box>
                    <Box width={"50%"}>
                      <Typography variant="h6">Excel</Typography>
                      <Reorder.Group
                        className={styles.reorderGroup}
                        axis="y"
                        values={excelTemplates || []}
                        onReorder={(value) => {
                          setExcelTemplates(value);
                        }}
                      >
                        {excelTemplates?.map((template: any, index: number) => (
                          <Item
                            key={template.id}
                            item={template}
                            index={index}
                          />
                        ))}
                      </Reorder.Group>
                    </Box>
                  </Box>
                </>
              )}
            </Paper>
          </>
        )}
        <Dialog open={openDialog} maxWidth="sm" fullWidth>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <Typography>
              {templateToView
                ? templateToView.name +
                  " created on " +
                  convertDate(templateToView.date_created)
                : "New template"}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleDialogClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[900],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 16,
            }}
          >
            <Stack spacing={2}>
              <Box display="flex" flexDirection="column" rowGap={2}>
                {templateToView?.image_url || selectedImageData !== "" ? (
                  <img
                    src={templateToView?.image_url || selectedImageData}
                    alt="Template"
                    className={styles.imagePreview}
                  />
                ) : null}
                <Dropzone onDrop={onImageFileSelect} maxFiles={1}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className={styles.dropzoneImage}>
                      {selectedImageFile ? (
                        <div className={styles.dropzoneContent}>
                          <AiFillCheckCircle
                            size={40}
                            style={{ marginBottom: 4 }}
                          />
                          <span>Success!</span>
                        </div>
                      ) : (
                        <div className={styles.dropzoneContent}>
                          <AiFillFileImage size={30} />
                          <span>
                            Drag and drop your image file or click here to
                            select a file
                          </span>
                          <input {...getInputProps()} />
                        </div>
                      )}
                    </div>
                  )}
                </Dropzone>
              </Box>
              <TextField
                label="Name"
                type="text"
                autoFocus
                required
                {...register("name")}
              />
              <TextField
                label="Description"
                type="text"
                multiline
                maxRows={3}
                required
                {...register("description")}
              />
              <RadioGroup defaultValue={templateToView?.platform || "EXCEL"}>
                <FormControlLabel
                  value="EXCEL"
                  control={<Radio {...register("platform")} />}
                  label="Excel"
                />
                <FormControlLabel
                  value="SHEETS"
                  control={<Radio {...register("platform")} />}
                  label="Sheets"
                />
              </RadioGroup>
              {watchPlatform === "EXCEL" ? (
                <>
                  {templateToView?.url ? (
                    <a href={templateToView?.url}>Download</a>
                  ) : null}
                  <Dropzone onDrop={onFileSelect} maxFiles={1}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} className={styles.dropzone}>
                        {selectedFile ? (
                          <div className={styles.dropzoneContent}>
                            <AiFillCheckCircle
                              size={40}
                              style={{ marginBottom: 4 }}
                            />
                            <span>Success!</span>
                          </div>
                        ) : (
                          <div className={styles.dropzoneContent}>
                            <BsCloudArrowUpFill size={40} />
                            <span>
                              Drag and drop your .xlsx/.xlsm file or click here
                              to select a file
                            </span>
                            <input {...getInputProps()} />
                          </div>
                        )}
                      </div>
                    )}
                  </Dropzone>
                </>
              ) : (
                <TextField
                  label="Sheets url"
                  type="text"
                  {...register("sheetsUrl")}
                  helperText="Be sure to share the sheet with no restrictions before adding the url here."
                />
              )}
              {DialogType === "edit" ? (
                <>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      setAreYouSure({
                        showAreYouSure: true,
                        areYouSureMessage:
                          "Are you sure you want to delete this template?",
                        areYouSureAction: () =>
                          deleteTemplate.mutate({ id: templateToView?.id }),
                      });
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="outlined"
                    color="info"
                    onClick={() => {
                      setAreYouSure({
                        showAreYouSure: true,
                        areYouSureMessage:
                          "Are you sure you want to update this templates status?",
                        areYouSureAction: () =>
                          updateTemplateStatus.mutate({
                            id: templateToView?.id,
                            status:
                              templateToView?.status === "PUBLISHED"
                                ? "UNPUBLISHED"
                                : "PUBLISHED",
                          }),
                      });
                    }}
                  >
                    {templateToView?.status === "PUBLISHED"
                      ? "Unpublish"
                      : "Publish"}
                  </Button>
                  <Button type="submit" variant="outlined">
                    Save
                  </Button>
                </>
              ) : (
                <Button type="submit" variant="outlined">
                  Add
                </Button>
              )}
            </Stack>
          </form>
        </Dialog>
      </Box>
    </>
  );
};

export default Templates;
