import { useMotionValue, Reorder, useDragControls } from "framer-motion";
import { useRaisedShadow } from "./use-raised-shadow";
import { ReorderIcon } from "./Icon";
import styles from "./Templates.module.css";

interface Props {
  item: any;
  index: number;
}

export const Item = ({ item, index }: Props) => {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  const dragControls = useDragControls();

  return (
    <Reorder.Item
      value={item}
      id={item.id}
      style={{ boxShadow, y }}
      dragListener={false}
      dragControls={dragControls}
      className={styles.item}
    >
      <span>
        {index + 1}. {item.name}
      </span>
      <ReorderIcon dragControls={dragControls} />
    </Reorder.Item>
  );
};
