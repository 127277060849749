import styles from "./Login.module.css";
import {
  Backdrop,
  Card,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCurrentUser } from "../../context/user-context";
import { httpClient } from "../../util/http";
import { AxiosResponse } from "axios";
import { GoogleLogin } from "@react-oauth/google";

const Login = () => {
  const navigate = useNavigate();
  const { isAuthenticated, setTokenInCache } = useCurrentUser();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (credentialResponse: any) => {
    setIsLoading(true);
    try {
      const response: AxiosResponse = await httpClient.post(
        "/api/public/users/authenticate",
        { token: credentialResponse.credential }
      );
      await setTokenInCache(response.data);
      setIsLoading(false);
      navigate("/admin/home");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/admin/home");
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <div className={styles.background}>
        <div className={styles.slanted}></div>
        <div className={styles.bgRect}></div>
      </div>
      <Box
        width={"100%"}
        height={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Card
          variant="elevation"
          sx={{ paddingX: 2, paddingY: 4, width: 400, zIndex: 10 }}
          elevation={2}
        >
          <Stack direction={"column"} alignItems={"center"}>
            <img
              src={require("../../logo.png")}
              alt="logo"
              height={100}
              width={100}
              className={styles.logo}
            />
            <Typography fontSize={28} marginBottom={2}>
              Wisesheets Portal
            </Typography>
            <GoogleLogin
              onSuccess={onSubmit}
              onError={() => console.log("Error while logging in with Google")}
            />
          </Stack>
        </Card>
      </Box>
    </>
  );
};

export default Login;
