import { Card, CardContent, Toolbar, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { httpClient } from "../../util/http";
import styles from "./Home.module.css";

const Home = () => {
  const totalUsersCount = useQuery("totalUsersCount", () =>
    httpClient.get("/api/private/stats/total-users")
  );

  const totalSubscribedUsersCount = useQuery("totalSubscribedUsersCount", () =>
    httpClient.get("/api/private/stats/total-subscribed-users")
  );

  const subRatio =
    (totalSubscribedUsersCount.data?.data.total_subscribed_users /
      totalUsersCount.data?.data.total_users) *
    100;

  const totalGssoUsersCount = useQuery("totalGssoUsersCount", () =>
    httpClient.get("/api/private/stats/total-gsso-users")
  );

  const totalSignupsToday = useQuery("totalSignupsToday", () =>
    httpClient.get("/api/private/stats/total-signups-today")
  );

  const totalManualSubs = useQuery("totalManualSubs", () =>
    httpClient.get("/api/private/stats/total-manual-subs")
  );

  return (
    <>
      <Toolbar />
      <div className={styles.container}>
        <Card>
          <CardContent
            sx={{ display: "flex", gap: "32px", alignItems: "center" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="body1" noWrap>
                Total manual subs:{" "}
                {totalManualSubs.data?.data.total_manual_subs}
              </Typography>
              <Typography variant="body1" noWrap>
                Total subscribed users:{" "}
                {totalSubscribedUsersCount.data?.data.total_subscribed_users}
              </Typography>
              <Typography variant="body1" noWrap>
                Total users: {totalUsersCount.data?.data.total_users}
              </Typography>
            </div>
            <Typography variant="h3" noWrap>
              {subRatio.toFixed(2)}%
            </Typography>
          </CardContent>
        </Card>
        <Card>
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography variant="body1" noWrap>
              Google SSO users:{" "}
              {totalGssoUsersCount.data?.data.total_gsso_users}
            </Typography>
          </CardContent>
        </Card>
        <Card>
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography variant="body1" noWrap>
              New users today:{" "}
              {totalSignupsToday.data?.data.total_signups_today}
            </Typography>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default Home;
